const Strings = {
  _i18nInterfaceStrings: {
    "Sefaria": "ספריא",
    "Topics":"נושאים",
    "Community": "קהילה",
    "Donate": "תרומה",
    "Sefaria Notifications": "הודעות בספריא",
    "Sefaria: a Living Library of Jewish Texts Online": "ספריא: ספריה יהודית דינמית",
    "Recently Viewed" : "נצפו לאחרונה",
    "The Sefaria Library": "תוכן העניינים של ספריא",
    "Sefaria Search": "חיפוש בספריא",
    "Sefaria Account": "חשבון בספריא",
    "Notifcations": "התראות",
    "New Additions to the Sefaria Library": "חידושים בארון הספרים של ספריא",
    "My Notes on Sefaria": "ההערות שלי בספריא",
    "Texts & Source Sheets from Torah, Talmud and Sefaria's library of Jewish sources.": "טקסטים ודפי מקורות מן התורה, התלמוד וספריית המקורות של ספריא.",
    "Moderator Tools": "כלי מנהלים",
    " with " : " עם ",
    "Connections" : "קשרים",
    " & ": " | ",
    "My Source Sheets" : "דפי המקורות שלי",
    "Public Source Sheets":"דפי מקורות פומביים",
    "Log in": "התחברות",
    "Sign up": "להרשמה",
    "Sign Up": "להרשמה",

    //Autocomplete
    "Books": "ספרים",
    "Terms": "מונחים",
    "Users": "משתמשים",
    "Categories": "קטגוריות",

    // Texts Page
    "Learn More": "לקריאה נוספת",
    "Learning Schedules": "לוח לימוד יומי",
    "Teach with Sefaria": "מלמדים עם ספריא",
    "Visualizations": "תרשימים גרפיים",
    "Torah Tab": "תורה טאב (תוסף)",
    "Get the Mobile App": "הורידו את היישומון",
    "Access the Jewish library anywhere and anytime with the": "בעזרת היישומון תוכלו לגשת לארון הספרים היהודי בכל מקום ובכל זמן.",
    "Sefaria mobile app.": "אפליקציית ספריא לנייד.",
    "Stay Connected": "הישארו מעודכנים",
    "Get updates on new texts, learning resources, features, and more.": "קבלו עדכונים על מקורות חדשים, כלי למידה חדשים ועוד.",
    "Support Sefaria": "תמכו בספריא",
    "Sefaria is an open source, nonprofit project. Support us by making a tax-deductible donation.": "ספריא היא מאגר פתוח וחינמי. תמכו בנו בעזרת תרומה.",
    "Make a Donation": "לתרומה",
    "Join the Conversation": "קחו חלק בשיח" ,
    "Explore the Community": "לעמוד הקהילה",
    "People around the world use Sefaria to create and share Torah resources. You're invited to add your voice.": "לומדים ולומדות מכל רחבי העולם משתמשים בספריא כדי ליצור ולשתף מקורות יהודיים. אנו מזמינים אתכם לקחת חלק בשיח זה.",
    "Create with Sefaria": "צרו בעזרת ספריא",
    "Mobile Apps": "יישומון לטלפון הנייד",
    "Browse the Library": "עיון בספריה",
    "Weekly Torah Portion": "פרשת השבוע",
    "All Learning Schedules": "לוחות לימוד נוספים",
    "Sponsor A Day of Learning": "תרמו יום לימוד בספריא",
    "With your help, we can add more texts and translations to the library, develop new tools for learning, and keep Sefaria accessible for Torah study anytime, anywhere.": "בעזרת התרומה שלכם נוכל להוסיף מקורות, תרגומים, לפתח כלים חדשים ללימוד ולשמור על גישה חופשית ללימוד תורה, בכל מקום ובכל זמן.",
    "Sponsor A Day": "הקדישו יום לימוד",

    // Text Category Pages
    "All Portions": "פרשות השבוע",
    "Popular Texts": "מקורות פופולריים",
    "Explore interconnections among texts with our interactive visualizations.": "העזרו בתרשימים הגרפיים כדי לגלות קשרים מעניינים בין מקורות.",
    "All Visualizations": "תרשימים גרפיים נוספים",
    "Daily Study": "לימוד יומי",

    // Topics and Category Admin tools
    "English Short Description for Table of Contents": "תיאור קצר עבור עמוד מפתח תוכן העניינים אנגלית",
    "Hebrew Short Description for Table of Contents": "תיאור קצר עבור עמוד מפתח תוכן העניינים עברית",
    "Choose a Category": "בחר קטגוריה",
    "Main Menu": "תפריט ראשי",
    "Saving topic information.": "מעדכן נתוני נושא",
    "Processing title changes may take some time.": "עדכון השינויים עלול להמשך זמן מה",
    "Add a short description.": "הוסף תיאור קצר",
    "Add a description.": "הוסף תיאור",
    "Add a title.": "הוסף שם כותר",
    "Explore Jewish Texts by Topic": "עיון בארון הספרים היהודי לפי נושא",
    "Explore by Topic": "לימוד לפי נושאים",
    "About Topics": "אודות \"נושאים\"",
    "Trending Topics": "נושאים נפוצים",
    "More": "עוד",
    "Less": "פחות",
    "All Topics A-Z": "כל הנושאים א-ת",
    "Browse or search our complete list of topics.": "חיפוש ברשימת הנושאים.",
    "Create a Topic": "ליצירת נושא חדש",
    "Edit Topic": "עריכת נושא",
    "English Title": "כותרת אנגלית",
    "Title": "כותרת",
    "English Description": "תיאור אנגלית",
    "Hebrew Description": "תיאור עברית",
    "Topic Deleted": "הנושא נמחק",
    "Topic Editor": "עריכת נושאים",
    "Please choose a category.": "אנא בחרו קטגוריה",
    "Title must be provided": "יש לספק כותרת",
    "Unfortunately, there may have been an error saving this topic information": "הודעת שגיאה: נראה כי חלה שגיאה במהלך שמירת הנתונים",
    "Something went wrong. Sorry!": "מצטערים, משהו השתבש",


    // Topics Tool
    "Search for a Topic.": "חפש נושא",
    "Add Topic": "הוספת נושא",
    "Create a new topic: ": "יצירת נושא: ",
    "Enter Source Ref (for example: 'Yevamot.62b.9-11' or 'Yevamot 62b:9-11')": "הכניסו מראה מקום, לדוגמא שבת פח א:ו",
    "Add sub-category": "הוסף קטגוריה",
    "Reorder sources": "סַדַּר המקורות",


    // All Topics
    "All Topics": "כל הנושאים",
    "Reset": "לאתחל",
    "Search Topics": "חפש נושאים",

    // Topic Images
    "Illustrative image" : "תמונה להמחשה",


    // Community Page
    "From the Community: Today on Sefaria": "מן הקהילה: היום בספריא",
    "Today on Sefaria": "היום בספריא",
    "Who to Follow": "משתמשים שכדאי לעקוב אחריהם",
    "Explore Collections": "עיינו באסופות",
    "Organizations, communities and individuals around the world curate and share collections of sheets for you to explore.": "ארגונים, קהילות ויחידים מרחבי העולם מציגים ומשתפים אסופות של דפי מקורות שתוכלו לגלות.",
    "Recently Published": "פורסמו לאחרונה",
    "Make a Sheet": "יצירת דף מקורות",
    "Combine sources from our library with your own comments, questions, images, and videos.": "השתמשו במקורות מהספריה ושלבו בהם את התגובות, השאלות, התמונות והסרטונים שלכם.",
    "A Living Library of Torah": "ספריה יהודית דינמית",

    // Search
    "Results for": "תוצאות עבור",
    "Results": "תוצאות",
    "Options": "אפשרויות",
    "Exact Matches Only": "התאמה מדויקת למילות החיפוש",
    "Search Texts": "חפש מקורות",
    "Search in this text": "חפש בטקסט",

    // Sheets
    "Sheet": "דף מקורות",
    "About this Sheet": "אודות דף המקורות",
    "Tags": "תוויות",
    "Search for a text...": "חפש מקור בספריא",
    "Paste a link to an image, video, or audio": "הוסף קישור לתמונה, סרטון, או אודיו",
    "Add media": "הוסף מדיה",
    "Add an image": "הוסף תמונה",
    "Add a source": "הוסף מקור",
    "Create a New Sheet": "יצירת דף מקורות",
    "Source Sheets": "דפי מקורות",
    "Sheets": "דפי מקורות",
    "Start a New Source Sheet": "התחלת דף מקורות חדש",
    "Untitled Source Sheet" : "דף מקורות ללא שם",
    "Untitled": "ללא שם",
    "New Source Sheet" : "דף מקורות חדש",
    "Name New Sheet" : "כותרת לדף חדש",
    "Copy" : "העתקה",
    "Edit": "עריכה",
    "View in Editor": "לתצוגת עריכה",
     "Enter link URL": "הכנס קישור",
    "Copied" : "הועתק",
    "Copying..." : "מעתיק...",
    "Delete": "מחיקה",
    "Sorry, there was a problem saving your note.": "סליחה, ארעה שגיאה בזמן השמירה",
    "Unfortunately, there was an error saving this note. Please try again or try reloading this page.": "ארעה שגיאה בזמן השמירה. אנא נסו שוב או טענו את הדף מחדש",
    "Are you sure you want to delete this note?": "האם אתם בטוחים שברצונכם למחוק?",
    "Something went wrong (that's all I know).":"משהו השתבש. סליחה",
    "Write a note...":"כתבו הערות כאן...",
    "Aa": "א",
    "Decrease font size": "הקטן גופן",
    "Increase font size": "הגדל גופן",
    "this comment":"הערה זו",
    "this source":"מקור זה",
    "was added to": "נוסף ל-",
    "View sheet": "מעבר ל-דף המקורות",
    "Please select a source sheet.": "אנא בחרו דף מקורות.",
    "New Source Sheet Name:" : "כותרת דף מקורות חדש:",
    "Source Sheet by" : "דף מקורות מאת",
    "Created with": 'נוצר עבורך ע"י',
    " hasn't shared any sheets yet.": " טרם שיתפ/ה דפי מקורות כלשהם",
    "Loading..." : "טוען...",
    "Saving..." : "שומר...",
    "Your Source Sheet has unsaved changes. Before leaving the page, click Save to keep your work.":
    "קיימים שינויים בלתי שמורים בדף המקורות. השתמשו בכפתור השמירה לפני עזיבת הדף.",
    "Your Source Sheet has unsaved changes. Please wait for the autosave to finish.":
    "קיימים שינויים בלתי שמורים בדף המקורות. אנא חכו שפעולת השמירה האוטומטית תסתיים.",
    "Are you sure you want to delete this sheet? There is no way to undo this action.":
    "מחיקת דף מקורות היא פעולה בלתי הפיכה. האם אתם בטוחים?",
    "Unfortunately an error has occurred. If you've recently edited text on this page, you may want to copy your recent work out of this page and click reload to ensure your work is properly saved.":
    "לצערנו ארעה שגיאה. אם ערכתם לאחרונה את הדף הנוכחי, ייתכן ותרצו להעתיק את השינויים למקור חיצוני ואז לטעון מחדש את הדף כדי לוודא שהשינויים נשמרו.",
    "Are you sure you want to remove this?": "בטוח שברצונך למחוק?",
    "Would you like to save this sheet? You only need to save once, after that changes are saved automatically.": "רוצה לשמור את הדף הזה? כל שעליך לעשות הוא לשמור פעם אחת – לאחר מכן השינויים יישמרו באופן אוטומטי.",
    "Like": "אהבתי",
    "Unlike": "ביטול סימון אהבתי",
    "No one has liked this sheet yet. Will you be the first?":
    "אף אחד עדיין לא אהב את דף המקורות הזה. תרצו להיות ראשונים?",
    "1 Person Likes This Sheet": "אדם אחד אהב את דף המקורות",
    " People Like This Sheet": " אנשים אהבו את דף המקורות",
    "Tags Saved": "תוית נשמרה",
    "Assignments allow you to create a template that your students can fill out on their own.":
    "מטלות מאפשרות ליצור דף בסיס שתלמידים יכולים להשתמש בו כדי למלא וליצור את העבודה שלהם.",
    "Students can complete their assignment at this link:":
    "תלמידים יכולים לבצע את המטלה שלהם בקישור הבא:",
    "Reset text of Hebrew, English or both?": "האם לאפס את התוכן של המקור בעברית, אנגלית או הכל?",
    "Any edits you have made to this source will be lost": "כל השינויים שנעשו במקור זה יאבדו",
    "Looking up Connections..." : "מחפש קישורים...",
    "No connections known for this source.": "למקור הזה אין קשרים ידועים",
    "Edit Source title" : "עריכת כותרת",
    "Add Source Below" : "הוספת מקור מתחת",
    "Add Source": "הוספת מקור",
    "Add Comment": "הוספת תגובה",
    "Add All Connections": "הוספת כל המקורות הקשורים",
    "Reset Source Text": "איפוס טקסט מקור",
    "Copy to Sheet" : "העתקה לדף מקורות",
    "Change Source Layout/Language": "שינוי שפת/עימוד מקור",
    "Move Source Up": "הזזת מקור מעלה",
    "Move Source Down": "הזזת מקור מטה",
    "Outdent Source": "הזחת מקור החוצה",
    "Indent Source": "הזחת מקור פנימה",
    "Create New" : "יצירת חדש",
    "Close" : "סגירה",
    "Reset": "איפוס",
    "Views": "צפיות",
    "Saves": "שמירות",

    //publish flow:
    "Not Published" : "לא פורסם",
    "Publish Settings": "הגדרות פרסום",
    "Publish Sheet": "פרסום דף מקורות",
    "Your sheet is": "דף המקורות שלך",
    "published": "פורסם",
    "on Sefaria and visible to others through search and topics.": ".בספריא ומשתמשים אחרים יכולים למצוא אותו בחיפוש ובדפי הנושא",
    "List your sheet on Sefaria for others to discover.": ".רישום דף המקורות שלך בספריא באופן שמשתמשים אחרים יוכלו למצוא אותו",
    "Summary": "תקציר דף המקורות",
    "Write a short description of your sheet..." : "תיאור קצר של דף המקורות...",
    "Add a topic...": "הוספת נושא...",
    "Publish": "פרסום דף המקורות",
    "Unpublish": "ביטול",
    "Please add a description and topics to publish your sheet.": "יש להוסיף תיאור ונושאים כדי לפרסם את הדף",
    "Please add topics to publish your sheet.": "יש להוסיף נושאים כדי לפרסם את הדף",
    "Please add a description to publish your sheet.": "יש להוסיף תיאור כדי לפרסם את הדף",
    "The summary description is limited to 280 characters.": "תקציר דף המקורות מוגבל ל280 תווים.",
    "People with this link can": "משתמשים עם קישור זה יכולים",
    "Publish your sheet on Sefaria for others to discover.": "פרסמו את דף המקורות בספריא כדי שאחרים יוכלו ללמוד ממנו.",

    // Reader Panel
    "Search" : "חיפוש",
    "Search Dictionary": "חיפוש במילון",
    "Search for": "חיפוש",
    "Search for:": "חיפוש:",
    "Views": "צפיות",
    "Search for Texts or Keywords Here": "חיפוש טקסט או מילות מפתח",
    "Versions": "מהדורות",
    "Version Open": "מהדורה פתוחה",
    "About": "אודות",
    "Current Version": "מהדורה נוכחית",
    "Current Translation": "תרגום נוכחי",
    "Select Version": "בחירת מהדורה",
    "Select Translation": "בחירת תרגום",
    "View in Sidebar": "פתיחת תרגום",
    'Select': 'בחירה',
    'Currently Selected': 'נוכחי',
    "Merged from": "נוצר ממיזוג",
    "Source" : "מקור",
    "Sources": "מקורות",
    "Digitization" : "דיגיטציה",
    "License" : "רשיון",
    "Revision History" : "היסטורית עריכה",
    "Buy in Print" : "לרכישה בדפוס",
    "Buy Now" : "רכישה",
    "Read More": "קרא עוד",
    "Web Pages": "דפי אינטרנט",
    "Members": "חברים",
    "Send": "שלח",
    "Cancel": "בטל",
    "Send a message to ": "שלח הודעה ל-",
    "Following": "נעקבים",
    "Followers": "עוקבים",
    "following": "נעקבים",
    "followers": "עוקבים",
    "Recent": "תאריך",
    "Unlisted": "חסוי",
    "History": "היסטוריה",
    "Digitized by Sefaria": 'הונגש ועובד לצורה דיגיטלית על ידי ספריא',
    "Public Domain": "בנחלת הכלל",
    "Copyright: JPS, 1985": "זכויות שמורות ל-JPS, 1985",
    "Location: ": "מיקום: ",
    "Translations": "תרגומים",
    "Uncategorized": "לא מסווג",
    "Text display options": "אפשרויות תצוגת טקסט",

    // Collections
    "Collections": "אסופות",
    "My Collections": "אסופות שלי",
    "Public Collections": "אסופות ציבוריות",
    "About Collections": "אודות \"אסופות\"",
    "Collection": "אסופה",
    "Collections of sheets are created by Sefaria users and can be privately, shared with friends, or made public on Sefaria.": "אסופות הן מקבצים של דפי מקורות שנוצרו על ידי משתמשי האתר. הן ניתנות לשימוש פרטי, לצורך שיתוף עם אחרים או לשימוש ציבורי באתר ספריא.",
    "Sefaria Collections": "אסופות של ספריא",
    "Create a Collection": "יצירת אסופה",
    "Create a New Collection": "יצירת אסופה חדשה",

    // Collection Page
    "Owner": "מנהל/ת",
    "Editor": "עורך/ה",
    "Editors": "עורכים",
    "There are no sheets in this collection yet.": "לאסופה זו טרם נוספו דפי מקורות.",
    "You can add sheets to this collection on your profile.": "באפשרותך להוסיף דפי מקורות לאסופה הזאת דרך הפרופיל האישי שלך.",
    "Open Profile": "לפרופיל האישי",
    "Search the full text of this collection for": "באפשרותך לחפש בטקסט המלא של האסופה את",
    "No sheets matching ": "לא נמצאו דפים מתאימים ל",
    "Pinned Sheet - click to unpin": "דף מקורות נעוץ - לחצו להסרה",
    "Pinned Sheet" : "דף מקורות נעוץ",
    "Pin Sheet" : "נעיצת דף מקורות",
    "can invite & edit settings": "יכולים לשלוח הזמנות ולערוך את ההגדרות",
    "can add & remove sheets": "יכולים להוסיף ולערוך דפים",
    "Leave Collection": "עזיבת האסופה",
    "Invite": "שליחת הזמנה",
    "Inviting...": "שולח...",
    "Invitation Sent": "נשלח",
    "Invited": "הוזמן",
    "Resend Invitation": "שליחת הזמנה חוזרת",
    "Invitation Resent": "ההזמנה החוזרת נשלחה",
    "There was an error sending your invitation.": "אירעה שגיאה בשליחת ההזמנה שלך.",
    "Are you sure you want to change your collection role? You won't be able to undo this action unless another owner restores your permissions.": "האם ברצונך לשנות את ההרשאה שלך באסופה? פעולה זו היא בלתי הפיכה, ולאחריה רק מנהלים אחרים של האסופה יוכל לשחזר את ההרשאות שלך.",
    "Are you sure you want to leave this collection?": "האם ברצונך לעזוב את האסופה?",
    "Are you sure you want to remove this person from this collection?": "האם ברצונך להסיר משתמש זה מן האסופה?",
    "Are you sure you want to remove this invitation?": "האם ברצונך למחוק הזמנה זו?",
    "There was an error pinning your sheet.": "אירעה שגיאה בצירוף דף המקורות.",

    // Public Collections Page
    "There are no public collections yet.": "טרם נוצרו אסופות ציבוריות",

    // Collections in Profile
    "You can use collections to organize your sheets or public sheets you like. Collections can be shared privately or made public on Sefaria.": "באפשרותך ליצור אסופות כדי לארגן את דפי המקורות שלך או דפי מקורות פתוחים לשימוש שאהבת. את האסופות אפשר לשתף באופן פרטי או לפרסם באופן ציבורי באתר ספריא.",
    " hasn't shared any collections yet.": " טרם שיתפ/ה אסופות כלשהם",
    "Create new collection": "יצירת אסופה חדשה",
    "Create": "יצירה",
    "Done": "סיום",
    "Add to Collection": "צירוף לאסופה",

    // Edit Collection page
    "Edit Collection": "עריכת אסופה",
    "Collection Name": "שם האסופה",
    "Website": "כתובת אתר",
    "Description": "תיאור",
    "Collection Image": "תמונת האסופה",
    "Upload Image": "העלאת תמונה",
    "Recommended size: 350px x 350px or larger": 'גודל מומלץ: לפחות 350 פיקסל ע"ג 350 פיקסל',
    "Default Sheet Header": "כותרת עמוד ראשונית",
    "Recommended size: 1000px width to fill sheet, smaller images align right": "גודל מומלץ: 1000 פיקסל כדי למלא את חלל הדף. גודל קטן יותר יתיישר לימין",
    "List on Sefaria": "הצג לכלל משתמשי ספריא",
    "Your collection will appear on the public collections page where others can find it.": "האסופה שלך תופיע בדף האסופות הציבוריות ותהיה זמינה לעיון של משתמשות ומשתמשים אחרים.",
    "Delete Collection": "מחיקת אסופה",
    "Are you sure you want to delete this collection? This cannot be undone.": "האם ברצונך למחוק את האסופה הזו? אין אפשרות לבטל את הפעולה אחר כך.",
    "You have unsaved changes to your collection.": "האסופה שלך כוללת שינויים שלא נשמרו.",
    "Images must be smaller than ": "תמונות חייבות להיות קטנות מ",
    "Unfortunately an error occurred uploading your file.": "אירעה שגיאה בהעלאת הקובץ שלך.",
    "Unfortunately an error occurred saving your collection.": "אירעה שגיאה בשמירת האסופה שלך.",
    "Unfortunately an error occurred deleting your collection.": "אירעה שגיאה במחיקת האסופה שלך.",

    // Saved / History / Notifications
    "Saved": "שמורים",
    "Notifications": "התראות",
    "Texts and sheets that you read will be available for you to see here.": "מקורות ודפי מקורות שקראתם יהיו זמינים עבורכם כאן לעיון חוזר.",
    "Click the bookmark icon on texts or sheets to save them here.": "לחצו על סמל הסימניה בתוך מקורות או דפי מקורות כדי לשמור מראי מקומות מסוימים כאן.",
    "New Text": "טקסט חדש",
    "sent you a message": "שלח/ה לך הודעה",
    "published a new sheet": "פרסמ/ה דף מקורות חדש",
    "liked your sheet": "שמר/ה את דף המקורות שלך",
    "is now following you": "נרשמ/ה לעדכונים ממך",
    "Reply": "תשובה",
    "added you to a collection": "הוסיפ/ה אותך לאסופה",

    // Learning Schedules
    "Daily Learning": "לימוד יומי",
    "Weekly Learning": "לימוד שבועי",
    "Since ancient times, the Torah has been divided into sections which are read each week on a set yearly calendar. Following this practice, many other calendars have been created to help communities of learners work through specific texts together.": "מימי קדם חולקה התורה לקטעי קריאה שבועיים שנועדו לסיום הספר כולו במשך תקופת זמן של שנה. בעקבות המנהג הזה התפתחו לאורך השנים סדרי לימוד תקופתיים רבים נוספים, ובעזרתם יכולות קהילות וקבוצות של לומדים ללמוד יחד טקסטים שלמים.",

    //languages
    "English": "אנגלית",
    "Hebrew": "עברית",
    "Yiddish": "יידיש",
    "Finnish": "פינית",
    "Portuguese": "פורטוגזית",
    "Spanish": "ספרדית",
    "French": "צרפתית",
    "German": "גרמנית",
    "Arabic": "ערבית",
    "Italian": "איטלקית",
    "Polish": "פולנית",
    "Russian": "רוסית",
    "Esperanto": "אספרנטו",
    "Persian": "פרסית",
    "Ladino" : "לאדינו",

    "On": "הצג",
    "Off": "הסתר",
    "Yes": "כן",
    "No": "לא",
    "Show Parasha Aliyot": "עליות לתורה מוצגות",
    "Hide Parasha Aliyot": "עליות לתורה מוסתרות",
    "Language": "שפה",
    "Layout": "עימוד",
    "Bilingual Layout" : "עימוד דו לשוני",
    "Color": "צבע",
    "Font Size" : "גודל גופן",
    "Aliyot" : "עליות לתורה",
    "Taamim and Nikkud" : "טעמים וניקוד",
    "Punctuation" : "פיסוק",
    "Show Punctuation": "הצגת סימני פיסוק",
    "Hide Punctuation": "הסתרת סימני פיסוק",
    "Show Vowels and Cantillation": "הצג טקסט עם טעמי מקרא וניקוד",
    "Vocalization": "טעמים וניקוד",
    "Vowels": "ניקוד",
    "Show only vowel points": "הצג טקסט עם ניקוד",
    "Show only consonantal text": "הצג טקסט עיצורי בלבד",
    "Email Address" : 'כתובת דוא"ל',
    "Describe the issue..." : "טקסט המשוב",
    "Report an issue with the text" : "דיווח על בעיה בטקסט",
    "Request translation" : "בקשה לתרגום",
    "Go to translations": "לרשימת התרגומים",
    "Report a bug" : "דיווח על תקלה באתר",
    "Get help" : "עזרה",
    "Help": "עזרה",
    "Get the Newsletter": "הירשמו לקבלת הניוזלטר",
    "Sign up to get updates": "הירשמו לקבלת עדכונים",
    "Request a feature": "בקשה להוספת אפשרות באתר",
    "Give thanks": "תודה",
    "Other": "אחר",
    "Please select a feedback type": "אנא בחרו סוג משוב",
    "Unfortunately, there was an error sending this feedback. Please try again or try reloading this page.": "לצערנו ארעה שגיאה בשליחת המשוב. אנא נסו שוב או רעננו את הדף הנוכחי",
    "Tell us what you think..." : "ספרו לנו מה אתם חושבים...",
    "Select Type" : "סוג משוב",
    "Added by" : "נוסף בידי",
    "Love Learning?": "אוהבים ללמוד?",
    "Sign up to get more from Sefaria" : "הרשמו כדי לקבל יותר מספריא",
    "Make source sheets": "הכינו דפי מקורות",
    "Take notes": "שמרו הערות",
    "Save texts": "שמרו טקסטים לקריאה חוזרת",
    "Follow your favorite authors": "עקבו אחר הסופרים האהובים עליכם",
    "Stay in the know": "השארו מעודכנים",
    "Already have an account?": "כבר יש לכם חשבון?",
    "Account Settings": "הגדרות",
    "Sign in": "התחברו",
    "Save": "שמירה",
    "Remove": "הסרה",
    "Logout": "ניתוק",
    "Email was successfully changed!": 'כתובת הדוא"ל שונתה בהצלחה!',
    "Settings Saved": "הגדרות נשמרו",
    "Turning this feature off will permanently delete your reading history." : "כיבוי אפשרות זו תמחק לצמיתות את היסטורית הקריאה שלך.",
    "Filter": "סינון",
    "Relevance": 'רלוונטיות',
    "Chronological": 'כרונולוגי',
    "Newest": "הכי חדש",
    "This source is connected to ": "מקור הזה קשור ל-",
    "This topic is connected to ": "נושא הזה קשור ל-",
    "by": "על ידי",
    "based on": "ע“פ",
    "research of Dr. Michael Sperling": "המחקר של ד\"ר מיכאל ספרלינג",
    "Read the Portion": "קראו את הפרשה",
    "My Notes": "הרשומות שלי",
    "Updates": "עדכונים",
    "Preferred Translation": "תרגום מועדף",
    "Text Title": "כותר לועזי",
    "Hebrew Title": "כותר עברי",
    "Text Structure": "מבנה טקסט",
    "Category": "קטגוריה",
    "Alternate Hebrew Titles": "חלופות כותר עבריות",
    "Alternate English Titles": "חלופות כותר לועזיות",
    "Optional": "אופציונלי",
    "Recently viewed": "נצפו לאחרונה",
    "All history ": "כל ההסטוריה ",
    "All History": "כל ההסטוריה",


     //About
    "What is Sefaria?": "מהי ספריא",
    "Team": "צוות ספריא",
    "Jobs at Sefaria": "משרות פנויות בספריא",
    "Our Supporters": "התומכים שלנו",
    "Metrics": "מדדים",
    "Annual Report": 'דו"ח שנתי',
    "Terms of Use": "תנאי שימוש",
    "Privacy Policy": "מדיניות פרטיות",

    //user stats
    "Torah Tracker" : "לימוד במספרים",
    "Previous Year": "לשנה האחרונה",
    "All Time": "כל הזמן",
    "Texts Read" : "ספרים שנקראו",
    "Sheets Read" : "דפי מקורות שנקראו",
    "Sheets Created" : "דפי מקורות שנוצרו",
    "Average Sefaria User" : "משתמש ממוצע בספריא",
    "Etc": "שאר",

    // Subscribe & Register
    "Please enter a valid email address.": 'כתובת הדוא"ל שהוזנה אינה תקינה.',
    "Subscribed! Welcome to our list.": "הרשמה בוצעה בהצלחה!",
    "Sorry, there was an error.": "סליחה, ארעה שגיאה",

    // Footer
    "Connect": "צרו קשר",
    "Site Language": "שפת האתר",

    //Profile
    "Profile": "פרופיל",
    " at ": " ב-",
    "on Sefaria": " בספריא",

    //New Editor Flow
    "Thanks for Trying the New Editor!": "תודה שבחרת לנסות את העורך החדש שלנו!",
    "Go to your profile to create a new sheet, or edit an existing sheet, to try out the new experience. After you’ve had a chance to try it out, we would love to hear your feedback. You can reach us at" : "כדי להתנסות בחוויית העריכה החדשה, יש להיכנס לפרופיל האישי שלך וליצור דף מקורות חדש או לערוך דף מקורות קיים. נשמח מאוד לקבל ממך משוב אחרי השימוש. אפשר ליצור איתנו קשר בדוא\"ל",
    "Back to Profile": "חזרה לפרופיל האישי שלך",
    "Go back to old version": "חזרה לגרסה הישנה",
    "Request for Feedback": "נא למלא משוב",
    "Thank you for trying the new Sefaria editor! We’d love to hear what you thought. Please take a few minutes to give us feedback on your experience.": "תודה שניסית את עורך ספריא החדש! נשמח מאוד לשמוע את דעתך עליו. אנו מבקשים ממך להקדיש כמה דקות למילוי משוב על חוויית השימוש שלך.",
    "Did you encounter any issues while using the new editor? For example:": "האם נתקלת בבעיות בעת השימוש בעורך החדש? למשל...",
    "Technical problems": "בעיות טכניות",
    "Difficulties using the editor": "קושי להשתמש בעורך",
    "Missing features": "תכונות חסרות",
    "Tell us about it...": "ספר/י לנו על כך...",
    "Submit Feedback": "לשליחת המשוב",
    "Thank you!": "תודה רבה!",
    "You are currently testing the new Sefaria editor.": "ברגע זה הינך עושה שימוש בעורך החדש של ספריא",
    "You are currently using the old Sefaria editor.": "כעת הינך עושה שימוש בגרסה הישנה של עורך ספריא",
    "Try the new version": "להתנסוּת בגרסה החדשה",
    "Your feedback is greatly appreciated. You can now edit your sheets again using the old source sheet editor. If you have any questions or additional feedback you can reach us at": "אנחנו מעריכים מאוד את המשוב שלך. כעת באפשרותך לחזור לערוך את הדפים שלך באמצעות עורך המסמכים הישן. לשאלות או למשוב נוסף אפשר לפנות אלינו בדוא\"ל:",

    //TextTableofContents
    "Contents": "תוכן",
    "Download": "הורדה",
    "Table of Contents": "תוכן העניינים",
    "Hebrew Versions": "בעברית",
    "English Versions": "באנגלית",
    "Continue Reading": "המשך קריאה",
    "Start Reading": "התחלת קריאה",
    "Composed": "זמן חיבור",
    "Author": "מחבר",
    "Authors": "מחברים",

    "Install Now": "התקינו עכשיו",

    //Topics
    "Wikipedia": "ויקיפדיה",
    "Jewish Encyclopedia": "האנציקלופדיה היהודית",
    "National Library of Israel": "הספרייה הלאומית",
    "Works on Sefaria": "חיבורים וכתבים בספריא",

    //Module Names
    "Download Text": "הורדת טקסט",
    "About this Text" : "אודות ספר זה",
    "About This Text" : "אודות ספר זה",
    "Related Topics": "נושאים קשורים",

    // FilterableList
    "Sort by": "מיון לפי",

    //Mobile Nav Header
    "Get Help": "עזרה", //already has a similar string here but with different capitalization, maybe fix bug when not right before a launch?
    "About Sefaria": "אודות ספריא",
    "Saved & History": "היסטורית קריאה ושמורים",

    //Misc
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam arcu felis, molestie sed mauris a, hendrerit vestibulum augue." : "בראיט ולחת צורק מונחף, בגורמי מג׳מש. תרבנך וסתעד לכנו סתשם השמה – לתכי מורגם בורק? לתיג ישבעס",

    //WebPage
    'Citing': 'מצטט',
    'Sites that are listed here use the': 'אתרים המפורטים כאן משתמשים',
    'Sefaria Linker': 'במרשתת ההפניות',

    //alt tags
    'Notifications': 'עדכונים',
    'Bookmarks': 'שמורים',
    'Toggle Interface Language Menu' : 'פתח תפריט שפת ממשק',
  },

  _i18nInterfaceStringsWithContext: {
    "AddConnectionBox": {
      "Select Type": "בחר סוג קישור",
      "None": "ללא",
      "Commentary": "פירוש",
      "Quotation": "ציטוט",
      "Midrash": "מדרש",
      "Ein Mishpat / Ner Mitsvah": "עין משפט / נר מצווה",
      'Mesorat HaShas': 'מסורת הש"ס',
      "Reference": "עיון",
      "Related Passage": "קשר אחר",
    },
    "SheetAuthorStatement": {
      "by ": "", // by line on sheets in reader, intentionally left blank
    },
    "AboutParashah": {
      "Torah Reading": "פרשת השבוע",
    },
    "ConnectionPanelSection":{
      "Related Texts": "מקורות לעיון",
      "Resources": "עזרים",
      "Tools": "כלים",
      "Share Link": "שיתוף קישור",
      "More Options": "אפשרויות נוספות",
    },
    "FollowButton": {
      "Follow": "עקבו",
      "Unfollow": "הסרת עוקב",
      "Following": "עוקב",
      "Follow Back": "עקבו בחזרה",
    },
    "DownloadVersions":{
      "Select Format": "בחירת פורמט הורדה",
      "Text (with Tags)": "טקסט (עם תיוגים)",
      "Text (without Tags)": "טקסט (ללא תיוגים)",
      "Select Version": "בחירת מהדורה/תרגום",
      "Merged Version": "גרסה משולבת"
    },
    "FilterableList": {
      "Alphabetical": "אלפביתי",
      "Recent": "הכי חדש",
      "Views": "הכי נצפה",
      /*"Relevance": 'רלוונטיות', //these 3 are duplicates but it doesnt seem to work without them, why?
      "Chronological": 'כרונולוגי',
      "Newest": "הכי חדש",*/
    },
    "Header": {
      "Texts": "מקורות",
    },
    "RecentlyPublished": {
      "Load More": "דפי מקורות נוספים",
    },
    "ResourcesModule": {
      "Resources": "כלי עזר",
    },
    "SearchFilters": {
      "Texts": "מקורות",
      "Topics": "נושאים",
      "Collections": "באסופות",
      "See More": "עוד",
    },
    "CalendarListing": {
      "Tanakh": "",
      "Talmud": ""
    },
    "Sheet Share": {
      "View": "לצפות",
      "Add": "להוסיף",
      "Edit": "לערוך",
    },
    "WebPageList": {
      'Author': 'מחבר'
    }
  },
};


export default Strings;
